
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmSettings,
  VmMenu 
} from '@vime/core';

import { define } from '../lib';
  

const SettingsInputs: string[] = [
  'pin',
  'active',
  'isMobile',
  'isAudioView',
];

const SettingsMethods: string[] = [
  'setController',
];

const SettingsOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Settings extends Components.VmSettings {}

@ProxyCmp({
  inputs: SettingsInputs,
  methods: SettingsMethods,
})
@Component({
  selector: 'vm-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: SettingsInputs,
  outputs: SettingsOutputs,
})
export class Settings {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-settings', VmSettings);
    define('vm-menu', VmMenu);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  