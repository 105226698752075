
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmPlayer 
} from '@vime/core';

import { define } from '../lib';
  

const PlayerInputs: string[] = [
  'logger',
  'theme',
  'icons',
  'paused',
  'playing',
  'duration',
  'mediaTitle',
  'currentProvider',
  'currentSrc',
  'currentPoster',
  'currentTime',
  'autoplay',
  'ready',
  'playbackReady',
  'loop',
  'muted',
  'buffered',
  'playbackRate',
  'playbackRates',
  'playbackQuality',
  'playbackQualities',
  'seeking',
  'debug',
  'playbackStarted',
  'playbackEnded',
  'buffering',
  'controls',
  'isControlsActive',
  'isSettingsActive',
  'volume',
  'isFullscreenActive',
  'aspectRatio',
  'viewType',
  'isAudioView',
  'isVideoView',
  'mediaType',
  'isAudio',
  'isVideo',
  'isLive',
  'isMobile',
  'isTouch',
  'isPiPActive',
  'textTracks',
  'currentTextTrack',
  'isTextTrackVisible',
  'shouldRenderNativeTextTracks',
  'audioTracks',
  'currentAudioTrack',
  'autopause',
  'playsinline',
  'language',
  'translations',
  'languages',
  'i18n',
];

const PlayerMethods: string[] = [
  'getProvider',
  'getAdapter',
  'play',
  'pause',
  'canPlay',
  'canAutoplay',
  'canMutedAutoplay',
  'canSetPlaybackRate',
  'canSetPlaybackQuality',
  'canSetFullscreen',
  'enterFullscreen',
  'exitFullscreen',
  'canSetPiP',
  'enterPiP',
  'exitPiP',
  'canSetAudioTrack',
  'setCurrentAudioTrack',
  'canSetTextTrack',
  'setCurrentTextTrack',
  'canSetTextTrackVisibility',
  'setTextTrackVisibility',
  'extendLanguage',
  'getContainer',
  'callAdapter',
];

const PlayerOutputs: string[] = [
  'vmThemeChange',
  'vmPausedChange',
  'vmPlay',
  'vmPlayingChange',
  'vmSeekingChange',
  'vmSeeked',
  'vmBufferingChange',
  'vmDurationChange',
  'vmCurrentTimeChange',
  'vmReady',
  'vmPlaybackReady',
  'vmPlaybackStarted',
  'vmPlaybackEnded',
  'vmBufferedChange',
  'vmError',
  'vmLoadStart',
  'vmCurrentProviderChange',
  'vmCurrentSrcChange',
  'vmCurrentPosterChange',
  'vmMediaTitleChange',
  'vmControlsChange',
  'vmPlaybackRateChange',
  'vmPlaybackRatesChange',
  'vmPlaybackQualityChange',
  'vmPlaybackQualitiesChange',
  'vmMutedChange',
  'vmVolumeChange',
  'vmViewTypeChange',
  'vmMediaTypeChange',
  'vmLiveChange',
  'vmTouchChange',
  'vmLanguageChange',
  'vmI18nChange',
  'vmTranslationsChange',
  'vmLanguagesChange',
  'vmFullscreenChange',
  'vmPiPChange',
  'vmTextTracksChange',
  'vmCurrentTextTrackChange',
  'vmTextTrackVisibleChange',
  'vmAudioTracksChange',
  'vmCurrentAudioTrackChange',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Player extends Components.VmPlayer {}

@ProxyCmp({
  inputs: PlayerInputs,
  methods: PlayerMethods,
})
@Component({
  selector: 'vm-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: PlayerInputs,
  outputs: PlayerOutputs,
})
export class Player {
  protected el: HTMLElement;

  /** Emitted when the `theme` prop changes value. */
  vmThemeChange!: Emitter<JSX.VmPlayer["onVmThemeChange"]>;
  /** Emitted when the `paused` prop changes value. */
  vmPausedChange!: Emitter<JSX.VmPlayer["onVmPausedChange"]>;
  /** Emitted when the media is transitioning from `paused` to `playing`. Event flow: `paused` ->
`play` -> `playing`. The media starts `playing` once enough content has buffered to
begin/resume playback. */
  vmPlay!: Emitter<JSX.VmPlayer["onVmPlay"]>;
  /** Emitted when the `playing` prop changes value. */
  vmPlayingChange!: Emitter<JSX.VmPlayer["onVmPlayingChange"]>;
  /** Emitted when the `seeking` prop changes value. */
  vmSeekingChange!: Emitter<JSX.VmPlayer["onVmSeekingChange"]>;
  /** Emitted directly after the player has successfully transitioned/seeked to a new time position.
Event flow: `seeking` -> `seeked`. */
  vmSeeked!: Emitter<JSX.VmPlayer["onVmSeeked"]>;
  /** Emitted when the `buffering` prop changes value. */
  vmBufferingChange!: Emitter<JSX.VmPlayer["onVmBufferingChange"]>;
  /** Emitted when the `duration` prop changes value. */
  vmDurationChange!: Emitter<JSX.VmPlayer["onVmDurationChange"]>;
  /** Emitted when the `currentTime` prop changes value. */
  vmCurrentTimeChange!: Emitter<JSX.VmPlayer["onVmCurrentTimeChange"]>;
  /** Emitted when the player has loaded and is ready to be interacted with. */
  vmReady!: Emitter<JSX.VmPlayer["onVmReady"]>;
  /** Emitted when the media is ready to begin playback. The following props are guaranteed to be
defined when this fires: `mediaTitle`, `currentSrc`, `currentPoster`, `duration`, `mediaType`,
`viewType`. */
  vmPlaybackReady!: Emitter<JSX.VmPlayer["onVmPlaybackReady"]>;
  /** Emitted when the media initiates playback. */
  vmPlaybackStarted!: Emitter<JSX.VmPlayer["onVmPlaybackStarted"]>;
  /** Emitted when playback reaches the end of the media. */
  vmPlaybackEnded!: Emitter<JSX.VmPlayer["onVmPlaybackEnded"]>;
  /** Emitted when the `buffered` prop changes value. */
  vmBufferedChange!: Emitter<JSX.VmPlayer["onVmBufferedChange"]>;
  /** Emitted when an any error has occurred within the player. */
  vmError!: Emitter<JSX.VmPlayer["onVmError"]>;
  /** Emitted when the provider starts loading a media resource. */
  vmLoadStart!: Emitter<JSX.VmPlayer["onVmLoadStart"]>;
  /** Emitted when the `currentProvider` prop changes value. */
  vmCurrentProviderChange!: Emitter<JSX.VmPlayer["onVmCurrentProviderChange"]>;
  /** Emitted when the `currentSrc` prop changes value. */
  vmCurrentSrcChange!: Emitter<JSX.VmPlayer["onVmCurrentSrcChange"]>;
  /** Emitted when the `currentPoster` prop changes value. */
  vmCurrentPosterChange!: Emitter<JSX.VmPlayer["onVmCurrentPosterChange"]>;
  /** Emitted when the `mediaTitle` prop changes value. */
  vmMediaTitleChange!: Emitter<JSX.VmPlayer["onVmMediaTitleChange"]>;
  /** Emitted when the `isControlsActive` prop changes value. */
  vmControlsChange!: Emitter<JSX.VmPlayer["onVmControlsChange"]>;
  /** Emitted when the `playbackRate` prop changes value. */
  vmPlaybackRateChange!: Emitter<JSX.VmPlayer["onVmPlaybackRateChange"]>;
  /** Emitted when the `playbackRates` prop changes value. */
  vmPlaybackRatesChange!: Emitter<JSX.VmPlayer["onVmPlaybackRatesChange"]>;
  /** Emitted when the `playbackQuality` prop changes value. */
  vmPlaybackQualityChange!: Emitter<JSX.VmPlayer["onVmPlaybackQualityChange"]>;
  /** Emitted when the `playbackQualities` prop changes value. */
  vmPlaybackQualitiesChange!: Emitter<JSX.VmPlayer["onVmPlaybackQualitiesChange"]>;
  /** Emitted when the `muted` prop changes value. */
  vmMutedChange!: Emitter<JSX.VmPlayer["onVmMutedChange"]>;
  /** Emitted when the `volume` prop changes value. */
  vmVolumeChange!: Emitter<JSX.VmPlayer["onVmVolumeChange"]>;
  /** Emitted when the `viewType` prop changes value. */
  vmViewTypeChange!: Emitter<JSX.VmPlayer["onVmViewTypeChange"]>;
  /** Emitted when the `mediaType` prop changes value. */
  vmMediaTypeChange!: Emitter<JSX.VmPlayer["onVmMediaTypeChange"]>;
  /** Emitted when the `isLive` prop changes value. */
  vmLiveChange!: Emitter<JSX.VmPlayer["onVmLiveChange"]>;
  /** Emitted when the `isTouch` prop changes value. */
  vmTouchChange!: Emitter<JSX.VmPlayer["onVmTouchChange"]>;
  /** Emitted when the `language` prop changes value. */
  vmLanguageChange!: Emitter<JSX.VmPlayer["onVmLanguageChange"]>;
  /** Emitted when the `i18n` prop changes value. */
  vmI18nChange!: Emitter<JSX.VmPlayer["onVmI18nChange"]>;
  /** Emitted when the `translations` prop changes value. */
  vmTranslationsChange!: Emitter<JSX.VmPlayer["onVmTranslationsChange"]>;
  /** Emitted when the `languages` prop changes value. */
  vmLanguagesChange!: Emitter<JSX.VmPlayer["onVmLanguagesChange"]>;
  /** Emitted when the `isFullscreenActive` prop changes value. */
  vmFullscreenChange!: Emitter<JSX.VmPlayer["onVmFullscreenChange"]>;
  /** Emitted when the `isPiPActive` prop changes value. */
  vmPiPChange!: Emitter<JSX.VmPlayer["onVmPiPChange"]>;
  /** Emitted when the `textTracks` prop changes value. */
  vmTextTracksChange!: Emitter<JSX.VmPlayer["onVmTextTracksChange"]>;
  /** Emitted when the `currentTextTrack` prop changes value. */
  vmCurrentTextTrackChange!: Emitter<JSX.VmPlayer["onVmCurrentTextTrackChange"]>;
  /** Emitted when the `isTextTrackVisible` prop changes value. */
  vmTextTrackVisibleChange!: Emitter<JSX.VmPlayer["onVmTextTrackVisibleChange"]>;
  /** Emitted when the `audioTracks` prop changes value. */
  vmAudioTracksChange!: Emitter<JSX.VmPlayer["onVmAudioTracksChange"]>;
  /** Emitted when the `currentAudioTrack` prop changes value. */
  vmCurrentAudioTrackChange!: Emitter<JSX.VmPlayer["onVmCurrentAudioTrackChange"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-player', VmPlayer);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmThemeChange', 'vmPausedChange', 'vmPlay', 'vmPlayingChange', 'vmSeekingChange', 'vmSeeked', 'vmBufferingChange', 'vmDurationChange', 'vmCurrentTimeChange', 'vmReady', 'vmPlaybackReady', 'vmPlaybackStarted', 'vmPlaybackEnded', 'vmBufferedChange', 'vmError', 'vmLoadStart', 'vmCurrentProviderChange', 'vmCurrentSrcChange', 'vmCurrentPosterChange', 'vmMediaTitleChange', 'vmControlsChange', 'vmPlaybackRateChange', 'vmPlaybackRatesChange', 'vmPlaybackQualityChange', 'vmPlaybackQualitiesChange', 'vmMutedChange', 'vmVolumeChange', 'vmViewTypeChange', 'vmMediaTypeChange', 'vmLiveChange', 'vmTouchChange', 'vmLanguageChange', 'vmI18nChange', 'vmTranslationsChange', 'vmLanguagesChange', 'vmFullscreenChange', 'vmPiPChange', 'vmTextTracksChange', 'vmCurrentTextTrackChange', 'vmTextTrackVisibleChange', 'vmAudioTracksChange', 'vmCurrentAudioTrackChange'])
  }
}
  