
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmScrubberControl,
  VmSlider,
  VmTooltip 
} from '@vime/core';

import { define } from '../lib';
  

const ScrubberControlInputs: string[] = [
  'alwaysShowHours',
  'hideTooltip',
  'currentTime',
  'duration',
  'noKeyboard',
  'buffering',
  'buffered',
  'i18n',
];

const ScrubberControlMethods: string[] = [
  
];

const ScrubberControlOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface ScrubberControl extends Components.VmScrubberControl {}

@ProxyCmp({
  inputs: ScrubberControlInputs,
  methods: ScrubberControlMethods,
})
@Component({
  selector: 'vm-scrubber-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ScrubberControlInputs,
  outputs: ScrubberControlOutputs,
})
export class ScrubberControl {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-scrubber-control', VmScrubberControl);
    define('vm-slider', VmSlider);
    define('vm-tooltip', VmTooltip);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  