
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmClickToPlay 
} from '@vime/core';

import { define } from '../lib';
  

const ClickToPlayInputs: string[] = [
  'useOnMobile',
  'paused',
  'isVideoView',
  'isMobile',
];

const ClickToPlayMethods: string[] = [
  'forceClick',
];

const ClickToPlayOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface ClickToPlay extends Components.VmClickToPlay {}

@ProxyCmp({
  inputs: ClickToPlayInputs,
  methods: ClickToPlayMethods,
})
@Component({
  selector: 'vm-click-to-play',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ClickToPlayInputs,
  outputs: ClickToPlayOutputs,
})
export class ClickToPlay {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-click-to-play', VmClickToPlay);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  