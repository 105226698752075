
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmDefaultUi,
  VmCaptionControl,
  VmControl,
  VmIcon,
  VmTooltip,
  VmCaptions,
  VmClickToPlay,
  VmControlGroup,
  VmControlSpacer,
  VmControls,
  VmCurrentTime,
  VmTime,
  VmDblClickFullscreen,
  VmDefaultControls,
  VmEndTime,
  VmFullscreenControl,
  VmLiveIndicator,
  VmMuteControl,
  VmPipControl,
  VmPlaybackControl,
  VmScrim,
  VmScrubberControl,
  VmSlider,
  VmSettingsControl,
  VmTimeProgress,
  VmVolumeControl,
  VmDefaultSettings,
  VmMenu,
  VmMenuItem,
  VmMenuRadio,
  VmMenuRadioGroup,
  VmSettings,
  VmSubmenu,
  VmLoadingScreen,
  VmPoster,
  VmSpinner,
  VmUi 
} from '@vime/core';

import { define } from '../lib';
  

const DefaultUiInputs: string[] = [
  'noClickToPlay',
  'noDblClickFullscreen',
  'noCaptions',
  'noPoster',
  'noSpinner',
  'noControls',
  'noSettings',
  'noLoadingScreen',
];

const DefaultUiMethods: string[] = [
  
];

const DefaultUiOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface DefaultUi extends Components.VmDefaultUi {}

@ProxyCmp({
  inputs: DefaultUiInputs,
  methods: DefaultUiMethods,
})
@Component({
  selector: 'vm-default-ui',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: DefaultUiInputs,
  outputs: DefaultUiOutputs,
})
export class DefaultUi {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-default-ui', VmDefaultUi);
    define('vm-caption-control', VmCaptionControl);
    define('vm-control', VmControl);
    define('vm-icon', VmIcon);
    define('vm-tooltip', VmTooltip);
    define('vm-captions', VmCaptions);
    define('vm-click-to-play', VmClickToPlay);
    define('vm-control-group', VmControlGroup);
    define('vm-control-spacer', VmControlSpacer);
    define('vm-controls', VmControls);
    define('vm-current-time', VmCurrentTime);
    define('vm-time', VmTime);
    define('vm-dbl-click-fullscreen', VmDblClickFullscreen);
    define('vm-default-controls', VmDefaultControls);
    define('vm-end-time', VmEndTime);
    define('vm-fullscreen-control', VmFullscreenControl);
    define('vm-live-indicator', VmLiveIndicator);
    define('vm-mute-control', VmMuteControl);
    define('vm-pip-control', VmPipControl);
    define('vm-playback-control', VmPlaybackControl);
    define('vm-scrim', VmScrim);
    define('vm-scrubber-control', VmScrubberControl);
    define('vm-slider', VmSlider);
    define('vm-settings-control', VmSettingsControl);
    define('vm-time-progress', VmTimeProgress);
    define('vm-volume-control', VmVolumeControl);
    define('vm-default-settings', VmDefaultSettings);
    define('vm-menu', VmMenu);
    define('vm-menu-item', VmMenuItem);
    define('vm-menu-radio', VmMenuRadio);
    define('vm-menu-radio-group', VmMenuRadioGroup);
    define('vm-settings', VmSettings);
    define('vm-submenu', VmSubmenu);
    define('vm-loading-screen', VmLoadingScreen);
    define('vm-poster', VmPoster);
    define('vm-spinner', VmSpinner);
    define('vm-ui', VmUi);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  