
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmDailymotion,
  VmEmbed 
} from '@vime/core';

import { define } from '../lib';
  

const DailymotionInputs: string[] = [
  'videoId',
  'shouldAutoplayQueue',
  'showUpNextQueue',
  'showShareButtons',
  'color',
  'syndication',
  'showDailymotionLogo',
  'showVideoInfo',
  'language',
  'autoplay',
  'controls',
  'poster',
  'logger',
  'loop',
  'muted',
  'playsinline',
];

const DailymotionMethods: string[] = [
  'getAdapter',
];

const DailymotionOutputs: string[] = [
  'vmError',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Dailymotion extends Components.VmDailymotion {}

@ProxyCmp({
  inputs: DailymotionInputs,
  methods: DailymotionMethods,
})
@Component({
  selector: 'vm-dailymotion',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: DailymotionInputs,
  outputs: DailymotionOutputs,
})
export class Dailymotion {
  protected el: HTMLElement;

  /** Emitted when an error has occurred. */
  vmError!: Emitter<JSX.VmDailymotion["onVmError"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-dailymotion', VmDailymotion);
    define('vm-embed', VmEmbed);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmError'])
  }
}
  