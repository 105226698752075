
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmFile 
} from '@vime/core';

import { define } from '../lib';
  

const FileInputs: string[] = [
  'willAttach',
  'crossOrigin',
  'preload',
  'poster',
  'mediaTitle',
  'controlsList',
  'autoPiP',
  'disablePiP',
  'disableRemotePlayback',
  'viewType',
  'playbackRates',
  'language',
  'autoplay',
  'controls',
  'logger',
  'loop',
  'muted',
  'playsinline',
  'noConnect',
  'paused',
  'currentTime',
  'volume',
  'playbackReady',
  'playbackStarted',
  'currentTextTrack',
  'hasCustomTextManager',
  'isTextTrackVisible',
  'shouldRenderNativeTextTracks',
];

const FileMethods: string[] = [
  'getAdapter',
];

const FileOutputs: string[] = [
  'vmError',
  'vmMediaElChange',
  'vmSrcSetChange',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface File extends Components.VmFile {}

@ProxyCmp({
  inputs: FileInputs,
  methods: FileMethods,
})
@Component({
  selector: 'vm-file',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: FileInputs,
  outputs: FileOutputs,
})
export class File {
  protected el: HTMLElement;

  /** Emitted when an error has occurred. */
  vmError!: Emitter<JSX.VmFile["onVmError"]>;
  /** Emitted when the underlying media element changes. */
  vmMediaElChange!: Emitter<JSX.VmFile["onVmMediaElChange"]>;
  /** Emitted when the child `<source />` elements are modified. */
  vmSrcSetChange!: Emitter<JSX.VmFile["onVmSrcSetChange"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-file', VmFile);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmError', 'vmMediaElChange', 'vmSrcSetChange'])
  }
}
  