
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmDash,
  VmFile,
  VmVideo 
} from '@vime/core';

import { define } from '../lib';
  

const DashInputs: string[] = [
  'src',
  'version',
  'libSrc',
  'config',
  'autoplay',
  'crossOrigin',
  'preload',
  'poster',
  'controlsList',
  'autoPiP',
  'disablePiP',
  'disableRemotePlayback',
  'mediaTitle',
  'enableTextTracksByDefault',
  'shouldRenderNativeTextTracks',
  'isTextTrackVisible',
  'currentTextTrack',
];

const DashMethods: string[] = [
  'getAdapter',
];

const DashOutputs: string[] = [
  'vmError',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Dash extends Components.VmDash {}

@ProxyCmp({
  inputs: DashInputs,
  methods: DashMethods,
})
@Component({
  selector: 'vm-dash',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: DashInputs,
  outputs: DashOutputs,
})
export class Dash {
  protected el: HTMLElement;

  /** Emitted when an error has occurred. */
  vmError!: Emitter<JSX.VmDash["onVmError"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-dash', VmDash);
    define('vm-file', VmFile);
    define('vm-video', VmVideo);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmError'])
  }
}
  