
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmDefaultSettings,
  VmIcon,
  VmMenu,
  VmMenuItem,
  VmMenuRadio,
  VmMenuRadioGroup,
  VmSettings,
  VmSubmenu 
} from '@vime/core';

import { define } from '../lib';
  

const DefaultSettingsInputs: string[] = [
  'pin',
  'i18n',
  'playbackReady',
  'playbackRate',
  'playbackRates',
  'isVideoView',
  'playbackQuality',
  'playbackQualities',
  'textTracks',
  'currentTextTrack',
  'audioTracks',
  'currentAudioTrack',
  'isTextTrackVisible',
];

const DefaultSettingsMethods: string[] = [
  
];

const DefaultSettingsOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface DefaultSettings extends Components.VmDefaultSettings {}

@ProxyCmp({
  inputs: DefaultSettingsInputs,
  methods: DefaultSettingsMethods,
})
@Component({
  selector: 'vm-default-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: DefaultSettingsInputs,
  outputs: DefaultSettingsOutputs,
})
export class DefaultSettings {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-default-settings', VmDefaultSettings);
    define('vm-icon', VmIcon);
    define('vm-menu', VmMenu);
    define('vm-menu-item', VmMenuItem);
    define('vm-menu-radio', VmMenuRadio);
    define('vm-menu-radio-group', VmMenuRadioGroup);
    define('vm-settings', VmSettings);
    define('vm-submenu', VmSubmenu);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  