
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmControls 
} from '@vime/core';

import { define } from '../lib';
  

const ControlsInputs: string[] = [
  'hidden',
  'fullWidth',
  'fullHeight',
  'direction',
  'align',
  'justify',
  'pin',
  'activeDuration',
  'waitForPlaybackStart',
  'hideWhenPaused',
  'hideOnMouseLeave',
  'isAudioView',
  'isSettingsActive',
  'playbackReady',
  'isControlsActive',
  'paused',
  'playbackStarted',
];

const ControlsMethods: string[] = [
  
];

const ControlsOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Controls extends Components.VmControls {}

@ProxyCmp({
  inputs: ControlsInputs,
  methods: ControlsMethods,
})
@Component({
  selector: 'vm-controls',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ControlsInputs,
  outputs: ControlsOutputs,
})
export class Controls {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-controls', VmControls);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  