
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmSettingsControl,
  VmControl,
  VmIcon,
  VmTooltip 
} from '@vime/core';

import { define } from '../lib';
  

const SettingsControlInputs: string[] = [
  'icon',
  'icons',
  'tooltipPosition',
  'tooltipDirection',
  'menu',
  'expanded',
  'i18n',
];

const SettingsControlMethods: string[] = [
  'focusControl',
  'blurControl',
];

const SettingsControlOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface SettingsControl extends Components.VmSettingsControl {}

@ProxyCmp({
  inputs: SettingsControlInputs,
  methods: SettingsControlMethods,
})
@Component({
  selector: 'vm-settings-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: SettingsControlInputs,
  outputs: SettingsControlOutputs,
})
export class SettingsControl {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-settings-control', VmSettingsControl);
    define('vm-control', VmControl);
    define('vm-icon', VmIcon);
    define('vm-tooltip', VmTooltip);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  