
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmMenuRadioGroup 
} from '@vime/core';

import { define } from '../lib';
  

const MenuRadioGroupInputs: string[] = [
  'value',
];

const MenuRadioGroupMethods: string[] = [
  
];

const MenuRadioGroupOutputs: string[] = [
  'vmCheck',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface MenuRadioGroup extends Components.VmMenuRadioGroup {}

@ProxyCmp({
  inputs: MenuRadioGroupInputs,
  methods: MenuRadioGroupMethods,
})
@Component({
  selector: 'vm-menu-radio-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: MenuRadioGroupInputs,
  outputs: MenuRadioGroupOutputs,
})
export class MenuRadioGroup {
  protected el: HTMLElement;

  /** Emitted when a new radio button is selected for this group. */
  vmCheck!: Emitter<JSX.VmMenuRadioGroup["onVmCheck"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-menu-radio-group', VmMenuRadioGroup);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmCheck'])
  }
}
  