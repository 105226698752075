
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmMenuRadio,
  VmIcon,
  VmMenuItem 
} from '@vime/core';

import { define } from '../lib';
  

const MenuRadioInputs: string[] = [
  'label',
  'value',
  'checked',
  'badge',
  'checkIcon',
  'icons',
];

const MenuRadioMethods: string[] = [
  
];

const MenuRadioOutputs: string[] = [
  'vmCheck',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface MenuRadio extends Components.VmMenuRadio {}

@ProxyCmp({
  inputs: MenuRadioInputs,
  methods: MenuRadioMethods,
})
@Component({
  selector: 'vm-menu-radio',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: MenuRadioInputs,
  outputs: MenuRadioOutputs,
})
export class MenuRadio {
  protected el: HTMLElement;

  /** Emitted when the radio button is selected. */
  vmCheck!: Emitter<JSX.VmMenuRadio["onVmCheck"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-menu-radio', VmMenuRadio);
    define('vm-icon', VmIcon);
    define('vm-menu-item', VmMenuItem);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmCheck'])
  }
}
  