
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmControlGroup 
} from '@vime/core';

import { define } from '../lib';
  

const ControlGroupInputs: string[] = [
  'space',
];

const ControlGroupMethods: string[] = [
  
];

const ControlGroupOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface ControlGroup extends Components.VmControlGroup {}

@ProxyCmp({
  inputs: ControlGroupInputs,
  methods: ControlGroupMethods,
})
@Component({
  selector: 'vm-control-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ControlGroupInputs,
  outputs: ControlGroupOutputs,
})
export class ControlGroup {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-control-group', VmControlGroup);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  