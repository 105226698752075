
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmSubmenu,
  VmIcon,
  VmMenu,
  VmMenuItem 
} from '@vime/core';

import { define } from '../lib';
  

const SubmenuInputs: string[] = [
  'label',
  'hint',
  'slideInDirection',
  'active',
];

const SubmenuMethods: string[] = [
  'getController',
  'getMenu',
  'getControllerHeight',
];

const SubmenuOutputs: string[] = [
  'vmOpenSubmenu',
  'vmCloseSubmenu',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Submenu extends Components.VmSubmenu {}

@ProxyCmp({
  inputs: SubmenuInputs,
  methods: SubmenuMethods,
})
@Component({
  selector: 'vm-submenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: SubmenuInputs,
  outputs: SubmenuOutputs,
})
export class Submenu {
  protected el: HTMLElement;

  /** Emitted when the submenu is open/active. */
  vmOpenSubmenu!: Emitter<JSX.VmSubmenu["onVmOpenSubmenu"]>;
  /** Emitted when the submenu has closed/is not active. */
  vmCloseSubmenu!: Emitter<JSX.VmSubmenu["onVmCloseSubmenu"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-submenu', VmSubmenu);
    define('vm-icon', VmIcon);
    define('vm-menu', VmMenu);
    define('vm-menu-item', VmMenuItem);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmOpenSubmenu', 'vmCloseSubmenu'])
  }
}
  