
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmCaptionControl,
  VmControl,
  VmIcon,
  VmTooltip 
} from '@vime/core';

import { define } from '../lib';
  

const CaptionControlInputs: string[] = [
  'showIcon',
  'hideIcon',
  'tooltipPosition',
  'tooltipDirection',
  'hideTooltip',
  'icons',
  'keys',
  'i18n',
  'playbackReady',
  'textTracks',
  'isTextTrackVisible',
];

const CaptionControlMethods: string[] = [
  
];

const CaptionControlOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface CaptionControl extends Components.VmCaptionControl {}

@ProxyCmp({
  inputs: CaptionControlInputs,
  methods: CaptionControlMethods,
})
@Component({
  selector: 'vm-caption-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: CaptionControlInputs,
  outputs: CaptionControlOutputs,
})
export class CaptionControl {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-caption-control', VmCaptionControl);
    define('vm-control', VmControl);
    define('vm-icon', VmIcon);
    define('vm-tooltip', VmTooltip);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  