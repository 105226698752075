
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmIconLibrary 
} from '@vime/core';

import { define } from '../lib';
  

const IconLibraryInputs: string[] = [
  'name',
  'resolver',
  'icons',
];

const IconLibraryMethods: string[] = [
  
];

const IconLibraryOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface IconLibrary extends Components.VmIconLibrary {}

@ProxyCmp({
  inputs: IconLibraryInputs,
  methods: IconLibraryMethods,
})
@Component({
  selector: 'vm-icon-library',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: IconLibraryInputs,
  outputs: IconLibraryOutputs,
})
export class IconLibrary {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-icon-library', VmIconLibrary);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  