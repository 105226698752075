
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmYoutube,
  VmEmbed 
} from '@vime/core';

import { define } from '../lib';
  

const YoutubeInputs: string[] = [
  'cookies',
  'videoId',
  'showFullscreenControl',
  'poster',
  'language',
  'autoplay',
  'controls',
  'logger',
  'loop',
  'muted',
  'playsinline',
];

const YoutubeMethods: string[] = [
  'getAdapter',
];

const YoutubeOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Youtube extends Components.VmYoutube {}

@ProxyCmp({
  inputs: YoutubeInputs,
  methods: YoutubeMethods,
})
@Component({
  selector: 'vm-youtube',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: YoutubeInputs,
  outputs: YoutubeOutputs,
})
export class Youtube {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-youtube', VmYoutube);
    define('vm-embed', VmEmbed);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  