
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmHls,
  VmFile,
  VmVideo 
} from '@vime/core';

import { define } from '../lib';
  

const HlsInputs: string[] = [
  'version',
  'libSrc',
  'config',
  'crossOrigin',
  'preload',
  'poster',
  'controlsList',
  'autoPiP',
  'disablePiP',
  'disableRemotePlayback',
  'playbackReady',
  'mediaTitle',
];

const HlsMethods: string[] = [
  'getAdapter',
];

const HlsOutputs: string[] = [
  'vmError',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Hls extends Components.VmHls {}

@ProxyCmp({
  inputs: HlsInputs,
  methods: HlsMethods,
})
@Component({
  selector: 'vm-hls',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: HlsInputs,
  outputs: HlsOutputs,
})
export class Hls {
  protected el: HTMLElement;

  /** Emitted when an error has occurred. */
  vmError!: Emitter<JSX.VmHls["onVmError"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-hls', VmHls);
    define('vm-file', VmFile);
    define('vm-video', VmVideo);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmError'])
  }
}
  