
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmMenu 
} from '@vime/core';

import { define } from '../lib';
  

const MenuInputs: string[] = [
  'active',
  'identifier',
  'controller',
  'slideInDirection',
];

const MenuMethods: string[] = [
  'focusMenu',
  'blurMenu',
  'getActiveMenuItem',
  'setActiveMenuItem',
  'calculateHeight',
];

const MenuOutputs: string[] = [
  'vmOpen',
  'vmClose',
  'vmFocus',
  'vmBlur',
  'vmActiveSubmenuChange',
  'vmActiveMenuItemChange',
  'vmMenuHeightChange',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Menu extends Components.VmMenu {}

@ProxyCmp({
  inputs: MenuInputs,
  methods: MenuMethods,
})
@Component({
  selector: 'vm-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: MenuInputs,
  outputs: MenuOutputs,
})
export class Menu {
  protected el: HTMLElement;

  /** Emitted when the menu is open/active. */
  vmOpen!: Emitter<JSX.VmMenu["onVmOpen"]>;
  /** Emitted when the menu has closed/is not active. */
  vmClose!: Emitter<JSX.VmMenu["onVmClose"]>;
  /** Emitted when the menu is focused. */
  vmFocus!: Emitter<JSX.VmMenu["onVmFocus"]>;
  /** Emitted when the menu loses focus. */
  vmBlur!: Emitter<JSX.VmMenu["onVmBlur"]>;
  /** Emitted when the active submenu changes. */
  vmActiveSubmenuChange!: Emitter<JSX.VmMenu["onVmActiveSubmenuChange"]>;
  /** Emitted when the currently focused menu item changes. */
  vmActiveMenuItemChange!: Emitter<JSX.VmMenu["onVmActiveMenuItemChange"]>;
  /** Emitted when the height of the menu changes. */
  vmMenuHeightChange!: Emitter<JSX.VmMenu["onVmMenuHeightChange"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-menu', VmMenu);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmOpen', 'vmClose', 'vmFocus', 'vmBlur', 'vmActiveSubmenuChange', 'vmActiveMenuItemChange', 'vmMenuHeightChange'])
  }
}
  