
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmTime 
} from '@vime/core';

import { define } from '../lib';
  

const TimeInputs: string[] = [
  'label',
  'seconds',
  'alwaysShowHours',
];

const TimeMethods: string[] = [
  
];

const TimeOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Time extends Components.VmTime {}

@ProxyCmp({
  inputs: TimeInputs,
  methods: TimeMethods,
})
@Component({
  selector: 'vm-time',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: TimeInputs,
  outputs: TimeOutputs,
})
export class Time {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-time', VmTime);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  