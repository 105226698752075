
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmVolumeControl,
  VmControl,
  VmIcon,
  VmMuteControl,
  VmTooltip,
  VmSlider 
} from '@vime/core';

import { define } from '../lib';
  

const VolumeControlInputs: string[] = [
  'lowVolumeIcon',
  'highVolumeIcon',
  'mutedIcon',
  'icons',
  'tooltipPosition',
  'tooltipDirection',
  'hideTooltip',
  'muteKeys',
  'noKeyboard',
  'muted',
  'volume',
  'isMobile',
  'i18n',
];

const VolumeControlMethods: string[] = [
  
];

const VolumeControlOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface VolumeControl extends Components.VmVolumeControl {}

@ProxyCmp({
  inputs: VolumeControlInputs,
  methods: VolumeControlMethods,
})
@Component({
  selector: 'vm-volume-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: VolumeControlInputs,
  outputs: VolumeControlOutputs,
})
export class VolumeControl {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-volume-control', VmVolumeControl);
    define('vm-control', VmControl);
    define('vm-icon', VmIcon);
    define('vm-mute-control', VmMuteControl);
    define('vm-tooltip', VmTooltip);
    define('vm-slider', VmSlider);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  