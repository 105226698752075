
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmDefaultControls,
  VmCaptionControl,
  VmControl,
  VmIcon,
  VmTooltip,
  VmControlGroup,
  VmControlSpacer,
  VmControls,
  VmCurrentTime,
  VmTime,
  VmEndTime,
  VmFullscreenControl,
  VmLiveIndicator,
  VmMuteControl,
  VmPipControl,
  VmPlaybackControl,
  VmScrim,
  VmScrubberControl,
  VmSlider,
  VmSettingsControl,
  VmTimeProgress,
  VmVolumeControl 
} from '@vime/core';

import { define } from '../lib';
  

const DefaultControlsInputs: string[] = [
  'activeDuration',
  'waitForPlaybackStart',
  'hideWhenPaused',
  'hideOnMouseLeave',
  'theme',
  'isMobile',
  'isLive',
  'isAudioView',
  'isVideoView',
];

const DefaultControlsMethods: string[] = [
  
];

const DefaultControlsOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface DefaultControls extends Components.VmDefaultControls {}

@ProxyCmp({
  inputs: DefaultControlsInputs,
  methods: DefaultControlsMethods,
})
@Component({
  selector: 'vm-default-controls',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: DefaultControlsInputs,
  outputs: DefaultControlsOutputs,
})
export class DefaultControls {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-default-controls', VmDefaultControls);
    define('vm-caption-control', VmCaptionControl);
    define('vm-control', VmControl);
    define('vm-icon', VmIcon);
    define('vm-tooltip', VmTooltip);
    define('vm-control-group', VmControlGroup);
    define('vm-control-spacer', VmControlSpacer);
    define('vm-controls', VmControls);
    define('vm-current-time', VmCurrentTime);
    define('vm-time', VmTime);
    define('vm-end-time', VmEndTime);
    define('vm-fullscreen-control', VmFullscreenControl);
    define('vm-live-indicator', VmLiveIndicator);
    define('vm-mute-control', VmMuteControl);
    define('vm-pip-control', VmPipControl);
    define('vm-playback-control', VmPlaybackControl);
    define('vm-scrim', VmScrim);
    define('vm-scrubber-control', VmScrubberControl);
    define('vm-slider', VmSlider);
    define('vm-settings-control', VmSettingsControl);
    define('vm-time-progress', VmTimeProgress);
    define('vm-volume-control', VmVolumeControl);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  