
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmUi 
} from '@vime/core';

import { define } from '../lib';
  

const UiInputs: string[] = [
  'isVideoView',
  'playsinline',
  'isFullscreenActive',
];

const UiMethods: string[] = [
  
];

const UiOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Ui extends Components.VmUi {}

@ProxyCmp({
  inputs: UiInputs,
  methods: UiMethods,
})
@Component({
  selector: 'vm-ui',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: UiInputs,
  outputs: UiOutputs,
})
export class Ui {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-ui', VmUi);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  