
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmControlSpacer 
} from '@vime/core';

import { define } from '../lib';
  

const ControlSpacerInputs: string[] = [
  
];

const ControlSpacerMethods: string[] = [
  
];

const ControlSpacerOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface ControlSpacer extends Components.VmControlSpacer {}

@ProxyCmp({
  inputs: ControlSpacerInputs,
  methods: ControlSpacerMethods,
})
@Component({
  selector: 'vm-control-spacer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ControlSpacerInputs,
  outputs: ControlSpacerOutputs,
})
export class ControlSpacer {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-control-spacer', VmControlSpacer);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  