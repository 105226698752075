
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmControl 
} from '@vime/core';

import { define } from '../lib';
  

const ControlInputs: string[] = [
  'keys',
  'identifier',
  'hidden',
  'label',
  'menu',
  'expanded',
  'pressed',
  'isTouch',
];

const ControlMethods: string[] = [
  'focusControl',
  'blurControl',
];

const ControlOutputs: string[] = [
  'vmInteractionChange',
  'vmFocus',
  'vmBlur',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Control extends Components.VmControl {}

@ProxyCmp({
  inputs: ControlInputs,
  methods: ControlMethods,
})
@Component({
  selector: 'vm-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ControlInputs,
  outputs: ControlOutputs,
})
export class Control {
  protected el: HTMLElement;

  /** Emitted when the user is interacting with the control by focusing, touching or hovering on it. */
  vmInteractionChange!: Emitter<JSX.VmControl["onVmInteractionChange"]>;
  /** Emitted when the control receives focus. */
  vmFocus!: Emitter<JSX.VmControl["onVmFocus"]>;
  /** Emitted when the control loses focus. */
  vmBlur!: Emitter<JSX.VmControl["onVmBlur"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-control', VmControl);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmInteractionChange', 'vmFocus', 'vmBlur'])
  }
}
  