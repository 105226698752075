
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { NgModule } from '@angular/core';

import {
  Audio,
  CaptionControl,
  Captions,
  ClickToPlay,
  Control,
  ControlGroup,
  ControlSpacer,
  Controls,
  CurrentTime,
  Dailymotion,
  Dash,
  DblClickFullscreen,
  DefaultControls,
  DefaultSettings,
  DefaultUi,
  Embed,
  EndTime,
  File,
  FullscreenControl,
  Hls,
  Icon,
  IconLibrary,
  LiveIndicator,
  LoadingScreen,
  Menu,
  MenuItem,
  MenuRadio,
  MenuRadioGroup,
  MuteControl,
  PipControl,
  PlaybackControl,
  Player,
  Poster,
  Scrim,
  ScrubberControl,
  Settings,
  SettingsControl,
  Skeleton,
  Slider,
  Spinner,
  Submenu,
  Time,
  TimeProgress,
  Tooltip,
  Ui,
  Video,
  Vimeo,
  VolumeControl,
  Youtube,
} from './components';

const DECLARATIONS = [
  Audio,
  CaptionControl,
  Captions,
  ClickToPlay,
  Control,
  ControlGroup,
  ControlSpacer,
  Controls,
  CurrentTime,
  Dailymotion,
  Dash,
  DblClickFullscreen,
  DefaultControls,
  DefaultSettings,
  DefaultUi,
  Embed,
  EndTime,
  File,
  FullscreenControl,
  Hls,
  Icon,
  IconLibrary,
  LiveIndicator,
  LoadingScreen,
  Menu,
  MenuItem,
  MenuRadio,
  MenuRadioGroup,
  MuteControl,
  PipControl,
  PlaybackControl,
  Player,
  Poster,
  Scrim,
  ScrubberControl,
  Settings,
  SettingsControl,
  Skeleton,
  Slider,
  Spinner,
  Submenu,
  Time,
  TimeProgress,
  Tooltip,
  Ui,
  Video,
  Vimeo,
  VolumeControl,
  Youtube,
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [],
  providers: [],
})
export class VimeModule {}
  