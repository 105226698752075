
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmVideo,
  VmFile 
} from '@vime/core';

import { define } from '../lib';
  

const VideoInputs: string[] = [
  'willAttach',
  'hasCustomTextManager',
  'crossOrigin',
  'preload',
  'poster',
  'controlsList',
  'autoPiP',
  'disablePiP',
  'disableRemotePlayback',
  'mediaTitle',
];

const VideoMethods: string[] = [
  'getAdapter',
];

const VideoOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Video extends Components.VmVideo {}

@ProxyCmp({
  inputs: VideoInputs,
  methods: VideoMethods,
})
@Component({
  selector: 'vm-video',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: VideoInputs,
  outputs: VideoOutputs,
})
export class Video {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-video', VmVideo);
    define('vm-file', VmFile);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  