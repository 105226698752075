
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmIcon 
} from '@vime/core';

import { define } from '../lib';
  

const IconInputs: string[] = [
  'name',
  'src',
  'label',
  'library',
  'icons',
];

const IconMethods: string[] = [
  'redraw',
];

const IconOutputs: string[] = [
  'vmLoad',
  'vmError',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Icon extends Components.VmIcon {}

@ProxyCmp({
  inputs: IconInputs,
  methods: IconMethods,
})
@Component({
  selector: 'vm-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: IconInputs,
  outputs: IconOutputs,
})
export class Icon {
  protected el: HTMLElement;

  /** Emitted when the icon has loaded. */
  vmLoad!: Emitter<JSX.VmIcon["onVmLoad"]>;
  /** Emitted when the icon failed to load. */
  vmError!: Emitter<JSX.VmIcon["onVmError"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-icon', VmIcon);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmLoad', 'vmError'])
  }
}
  