
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmEmbed 
} from '@vime/core';

import { define } from '../lib';
  

const EmbedInputs: string[] = [
  'embedSrc',
  'mediaTitle',
  'params',
  'origin',
  'preconnections',
  'decoder',
];

const EmbedMethods: string[] = [
  'postMessage',
];

const EmbedOutputs: string[] = [
  'vmEmbedSrcChange',
  'vmEmbedMessage',
  'vmEmbedLoaded',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Embed extends Components.VmEmbed {}

@ProxyCmp({
  inputs: EmbedInputs,
  methods: EmbedMethods,
})
@Component({
  selector: 'vm-embed',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: EmbedInputs,
  outputs: EmbedOutputs,
})
export class Embed {
  protected el: HTMLElement;

  /** Emitted when the `embedSrc` or `params` props change. The payload contains the `params`
serialized into a query string and appended to `embedSrc`. */
  vmEmbedSrcChange!: Emitter<JSX.VmEmbed["onVmEmbedSrcChange"]>;
  /** Emitted when a new message is received from the embedded player via `postMessage`. */
  vmEmbedMessage!: Emitter<JSX.VmEmbed["onVmEmbedMessage"]>;
  /** Emitted when the embedded player and any new media has loaded. */
  vmEmbedLoaded!: Emitter<JSX.VmEmbed["onVmEmbedLoaded"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-embed', VmEmbed);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmEmbedSrcChange', 'vmEmbedMessage', 'vmEmbedLoaded'])
  }
}
  