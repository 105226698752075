
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmLiveIndicator 
} from '@vime/core';

import { define } from '../lib';
  

const LiveIndicatorInputs: string[] = [
  'isLive',
  'i18n',
];

const LiveIndicatorMethods: string[] = [
  
];

const LiveIndicatorOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface LiveIndicator extends Components.VmLiveIndicator {}

@ProxyCmp({
  inputs: LiveIndicatorInputs,
  methods: LiveIndicatorMethods,
})
@Component({
  selector: 'vm-live-indicator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: LiveIndicatorInputs,
  outputs: LiveIndicatorOutputs,
})
export class LiveIndicator {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-live-indicator', VmLiveIndicator);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  