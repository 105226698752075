
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmSlider 
} from '@vime/core';

import { define } from '../lib';
  

const SliderInputs: string[] = [
  'step',
  'min',
  'max',
  'value',
  'valueText',
  'label',
];

const SliderMethods: string[] = [
  
];

const SliderOutputs: string[] = [
  'vmValueChange',
  'vmFocus',
  'vmBlur',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Slider extends Components.VmSlider {}

@ProxyCmp({
  inputs: SliderInputs,
  methods: SliderMethods,
})
@Component({
  selector: 'vm-slider',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: SliderInputs,
  outputs: SliderOutputs,
})
export class Slider {
  protected el: HTMLElement;

  /** Emitted when the value of the underlying `input` field changes. */
  vmValueChange!: Emitter<JSX.VmSlider["onVmValueChange"]>;
  /** Emitted when the slider receives focus. */
  vmFocus!: Emitter<JSX.VmSlider["onVmFocus"]>;
  /** Emitted when the slider loses focus. */
  vmBlur!: Emitter<JSX.VmSlider["onVmBlur"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-slider', VmSlider);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmValueChange', 'vmFocus', 'vmBlur'])
  }
}
  