
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmEndTime,
  VmTime 
} from '@vime/core';

import { define } from '../lib';
  

const EndTimeInputs: string[] = [
  'duration',
  'i18n',
  'alwaysShowHours',
];

const EndTimeMethods: string[] = [
  
];

const EndTimeOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface EndTime extends Components.VmEndTime {}

@ProxyCmp({
  inputs: EndTimeInputs,
  methods: EndTimeMethods,
})
@Component({
  selector: 'vm-end-time',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: EndTimeInputs,
  outputs: EndTimeOutputs,
})
export class EndTime {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-end-time', VmEndTime);
    define('vm-time', VmTime);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  