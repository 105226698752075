
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmSpinner 
} from '@vime/core';

import { define } from '../lib';
  

const SpinnerInputs: string[] = [
  'isVideoView',
  'currentProvider',
  'showWhenMediaLoading',
  'playbackReady',
  'buffering',
];

const SpinnerMethods: string[] = [
  
];

const SpinnerOutputs: string[] = [
  'vmWillShow',
  'vmWillHide',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Spinner extends Components.VmSpinner {}

@ProxyCmp({
  inputs: SpinnerInputs,
  methods: SpinnerMethods,
})
@Component({
  selector: 'vm-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: SpinnerInputs,
  outputs: SpinnerOutputs,
})
export class Spinner {
  protected el: HTMLElement;

  /** Emitted when the spinner will be shown. */
  vmWillShow!: Emitter<JSX.VmSpinner["onVmWillShow"]>;
  /** Emitted when the spinner will be hidden. */
  vmWillHide!: Emitter<JSX.VmSpinner["onVmWillHide"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-spinner', VmSpinner);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmWillShow', 'vmWillHide'])
  }
}
  