
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmScrim 
} from '@vime/core';

import { define } from '../lib';
  

const ScrimInputs: string[] = [
  'gradient',
  'isVideoView',
  'isControlsActive',
];

const ScrimMethods: string[] = [
  
];

const ScrimOutputs: string[] = [
  
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Scrim extends Components.VmScrim {}

@ProxyCmp({
  inputs: ScrimInputs,
  methods: ScrimMethods,
})
@Component({
  selector: 'vm-scrim',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: ScrimInputs,
  outputs: ScrimOutputs,
})
export class Scrim {
  protected el: HTMLElement;

  

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-scrim', VmScrim);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, [])
  }
}
  