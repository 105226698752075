
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone } from '@angular/core';
import { ProxyCmp, initOutputs } from '../lib';
import type { JSX, Components } from '@vime/core/dist/types';

import { 
  VmPoster 
} from '@vime/core';

import { define } from '../lib';
  

const PosterInputs: string[] = [
  'fit',
  'isVideoView',
  'currentPoster',
  'mediaTitle',
  'playbackStarted',
  'currentTime',
];

const PosterMethods: string[] = [
  
];

const PosterOutputs: string[] = [
  'vmLoaded',
  'vmWillShow',
  'vmWillHide',
];

export type Emitter<T extends ((...args: any[]) => any) | undefined> = EventEmitter<Parameters<Exclude<T, undefined>>[0]>;

export declare interface Poster extends Components.VmPoster {}

@ProxyCmp({
  inputs: PosterInputs,
  methods: PosterMethods,
})
@Component({
  selector: 'vm-poster',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<ng-content></ng-content>',
  inputs: PosterInputs,
  outputs: PosterOutputs,
})
export class Poster {
  protected el: HTMLElement;

  /** Emitted when the poster has loaded. */
  vmLoaded!: Emitter<JSX.VmPoster["onVmLoaded"]>;
  /** Emitted when the poster will be shown. */
  vmWillShow!: Emitter<JSX.VmPoster["onVmWillShow"]>;
  /** Emitted when the poster will be hidden. */
  vmWillHide!: Emitter<JSX.VmPoster["onVmWillHide"]>;

  constructor(c: ChangeDetectorRef, r: ElementRef, protected z: NgZone) {
    define('vm-poster', VmPoster);
    c.detach();
    this.el = r.nativeElement;
    initOutputs(this, ['vmLoaded', 'vmWillShow', 'vmWillHide'])
  }
}
  